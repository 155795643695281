//
// Article
// --------------------------------------------------

.mainContent {
  .page-title {
    font-size: 1.6470588235rem;
    text-align: center;
  }
  // REGISTREREN
  &.register {
    text-align: center;
    .page-title {
      font-size: 1.7647058824rem;
      margin-bottom: 0.4333333333em;
    }
    p {
      line-height: 1.4705882353em;
    }
  }
  // INLOGGEN & WACHTWOORD VERGETEN
  &.login,
  &.password {
    text-align: center;
    .page-title {
      margin-bottom: 1em;
    }
  }
  &.kraak-noot {
    // padding-top: 5px;
    text-align: center;
  }
  // THEMAS
  &.thema {
    background: #ffffff;
    .thema-head {
      padding: 2rem 0 0 0;
      .title {
        color: @black;
        font-size: 1.6470588235rem;
        margin-bottom: 0.3928571429em;
        i[class^="icon-"],
        i[class*=" icon-"] {
          margin-right: 10px;
        }
      }
      h1 {
        line-height: 3.5rem;

        i {
          color: #c4007a;
          opacity: 0.5;
          font-size: 3.5rem;
          line-height: 3.5rem;
          vertical-align: middle;
        }
      }
      p {
        color: @black;
        margin-bottom: 10px;
      }
      .thema-status {
        div {
          &.is-active {
            padding-left: 12px;
            padding-right: 12px;
            background-color: #c4007a;
            border-color: #a00064;
            color: #ffffff !important;
            border-radius: 2rem;
            i[class^="icon-"],
            i[class*=" icon-"] {
              // color: @white;
              font-size: 1.2rem;
              margin-right: 6px;
            }
            // span {
            //   display: inline-block;
            //   font-size: 1rem;
            //   line-height: 1.25rem;
            //   padding: 1rem 0;
            // }
            // &:hover {
            //   background: rgba(0,0,0,0.05);
            //   border: 2px dashed #c4007a;
            // }
          }
          i[class^="icon-"],
          i[class*=" icon-"] {
            font-size: 1rem;
          }
          &.is-inactive {
            i[class^="icon-"],
            i[class*=" icon-"] {
              display: none;
            }
          }
        }
      }
    }
  }
  .text-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: (40/18) * 1rem;
    padding: 35px 35px (68/18) *1rem;
    margin-bottom: 4rem;
    color: black;
    a {
      text-decoration: underline;
    }
    p {
      text-align: center;
    }
    h3 {
      font-size: (24/18) * 1rem;
      text-align: center;
      color: @black;
      margin-left: (15/18) * 1rem;
      margin-bottom: 1.5rem;
    }
    .btn {
      max-width: 324px;
      margin-bottom: (39/18) * 1rem;
    }
    .listing-checks {
      max-width: 425px;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }
  // NOOT VAN DE DAG
}
.thema-back {
  border-top: 2px dashed rgba(0, 0, 0, 0.1);
  padding: 1rem 0 1rem 0;
  a {
    text-decoration: underline;
    &:hover {
      font-weight: 800;
    }
  }
}

@media (min-width: @bp-small) {
  .mainContent {
    // THEMAS
    &.thema {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}
