//
// Score
// --------------------------------------------------

// SCORE VERKORTE LIBRA
.mainContent.libra-score {
  padding-top: 30px;
  padding-bottom: 60px;
  .page-title {
    color: @black;
    margin-bottom: 0.5em;
  }
  .text-box {
    h3 {
      margin-block-end: 21px;
      color: @color-hjernesundtesten4;
    }
    .register {
      margin-block: 28px 23px;
      border-radius: 4px;
    }
    p {
      margin-block-end: 0;
    }
  }
}
.score-top {
  padding: 0 0 (34/18)*1rem 0;
  text-align: center;
  h5 {
    font-size: (20/18)*1rem;
  }
  p {
    .fontSemibold;
    font-size: (20/18)*1rem;
    line-height: (32/20)*1em;
    margin-bottom: 0.5rem;
    color: @color-text;
  }
  a.btn {
    display: inline-block;
    min-width: 80%;
    margin: 1rem auto 0 auto;
  }

  .filters {
    display: flex;
    margin-top: 1.5rem;
    margin-bottom: (34/18)*1rem;
  }

  .filter {
    border-radius: 2rem;
    border: 3px solid #444;
    color: #444;
    background-color: #fff;
    font-weight: 700;
    padding: (11/18)*1rem (25/18)*1rem;
    cursor: pointer;
    text-decoration: none;
    margin-right: 0.5rem;
    line-height: 1.3;

    @media (max-width: 480px) {
      padding: 0.5rem;
      border-radius: 1.5rem;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: lighten(#444, 7%);

      &.filter--category-improve {
        background-color: mix(@color-hjernesundtesten2, #fff, 33%);
      }
      &.filter--category-monitor {
        background-color: mix(@color-hjernesundtesten4, #fff, 33%);
      }
      &.filter--category-maintain {
        background-color: mix(@color-green, #fff, 33%);
      }
    }

    &.filter--category-improve {
      border-color: @color-hjernesundtesten2;
      color: @color-hjernesundtesten2;
    }
    &.filter--category-monitor {
      border-color: @color-hjernesundtesten4;
      color: @color-hjernesundtesten4;
    }
    &.filter--category-maintain {
      border-color: @color-green;
      color: @color-green;
    }

    &.filter--active {
      background-color: #444;
      color: #fff;

      &.filter--category-improve {
        background-color: @color-hjernesundtesten2;
      }
      &.filter--category-monitor {
        background-color: @color-hjernesundtesten4;
      }
      &.filter--category-maintain {
        background-color: @color-green;
      }
    }
  }
}

.score-cloud {
  background-color: @white;
  margin-bottom: 45px;
  padding: 15px 15px 30px;
  position: relative;
  text-align: center;
  .border-radius(30px);
  .deco {
    left: 50%;
    position: absolute;
    bottom: -25px;
    .translate(-50%, 0);
  }
  .score-cloud-scores {
    height: 220px;
    margin: 0 auto 15px;
    position: relative;
    width: 260px;
  }
  .theme {
    color: #b7b7b7;
    position: absolute;
    &.is-hold-on {
      color: @color-success;
    }
    &.is-room-improvement {
      color: @color-secondary;
      .improvement-indicator {
        background-color: @color-secondary;
        border: 2px solid @white;
        color: @white;
        font-size: 1rem;
        .fontSemibold;
        height: 25px;
        left: -12px;
        line-height: 1em;
        padding: 2px 0;
        position: absolute;
        top: 55%;
        width: 25px;
        .border-radius(25px);
        .translate(0, -50%);
      }
    }
    i {
      font-size: 3rem;
      &.icon-beweging,
      &.icon-cholestrol {
        font-size: 3.2rem;
      }
      &.icon-alcohol {
        font-size: 2.7rem;
      }
      &.icon-roken,
      &.icon-dieet,
      &.icon-diabetes {
        font-size: 2.5rem;
      }
      &.icon-overgewicht {
        font-size: 2.3rem;
      }
    }
    &.theme-1 { left: 40px; top: 15px; }
    &.theme-2 { left: 105px; top: 14px; }
    &.theme-3 { left: 169px; top: 13px; }
    &.theme-4 { left: 7px; top: 68px; }
    &.theme-5 { left: 75px; top: 83px; }
    &.theme-6 { left: 139px; top: 75px; }
    &.theme-7 { left: 210px; top: 68px; }
    &.theme-8 { left: 17px; top: 132px; }
    &.theme-9 { left: 64px; top: 164px; }
    &.theme-10 { left: 115px; top: 126px; }
    &.theme-11 { left: 155px; top: 165px; }
    &.theme-12 { left: 205px; top: 129px; }
  }
  p {
    color: @black;
    line-height: 1.4705882353em;
    margin-bottom: 0;
    strong {
      color: @color-secondary;
    }
    &.is-hold-on {
      strong {
        color: @color-success;
      }
    }
  }
}

.score-info {
  text-align: center;
  .logo {
    min-width: 200px;
    max-width: 260px;
    margin: 0 auto 20px auto;
    img {
      width: 100% !important;
      height: auto;
    }
  }
  p {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    color: @black;
    line-height: 1.4705882353em;
  }
  .btn {
    display: block;
    margin-bottom: 15px;
  }
  .qr-code {
    margin-bottom: 15px;
    img {
      background-color: @white;
    }
  }
  .redo {
    color: @black;
    .fontRegular;
    text-decoration: underline;
    text-transform: lowercase;
    &:hover {
      .opacity(1);
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      border-bottom: 2px solid rgba(0,0,0,0.1);
      padding: 1rem 0 1rem 0;
      a {
        color: @black;
        .fontRegular;
        text-decoration: underline;
        &:hover {
          font-weight: 800;
          .opacity(1);
        }
      }
    }
  }
}

@media (min-width: @bp-x-small) {
  .score-cloud {
    .score-cloud-scores {
      height: 270px;
      .scale(1.2);
      -webkit-transform-origin: top center;
      -moz-transform-origin: top center;
      -ms-transform-origin: top center;
      -o-transform-origin: top center;
      transform-origin: top center;
    }
  }
}

@media (min-width: 600px) {
  .score-cloud {
    .score-cloud-scores {
      height: 300px;
      .scale(1.3);
    }
  }
}

@media (min-width: @bp-small) {
  .score-cloud {
    .score-cloud-scores {
      height: 330px;
      .scale(1.4);
    }
  }
}

@media (min-width: @bp-medium) {
  .score-cloud {
    .score-cloud-scores {
      height: 350px;
      .scale(1.5);
    }
    p {
      font-size: 1.1764705882rem;
    }
  }
}
// Accordion
.libra-score {
  .panel-group {
    .panel-default {
      border-radius: 1rem;
      overflow: hidden;
      margin-bottom: 20px;
      border: 2px solid rgba(0, 0, 0, 0.1);
      .panel-body {
        border: none !important;
        color: black;
        font-size: (16/18) * 1rem;
        padding: 0 2rem 2rem 5rem;
      }
    }
  }
  .panel-heading {
    background-color: @white;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0.5rem 1.2rem;
    .accordion-toggle {
      position: relative;
      text-decoration: none;
      font-size: (23/18) * 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: @color-text;
      &::after {
        content: "";
        position: absolute;
        right: 0;
        width: 32px;
        height: 32px;
        mask-image: url(../images/svg/chevron-right.svg);
        -webkit-mask-image: url(../images/svg/chevron-right.svg);
        -webkit-mask-size: cover;
        mask-size: cover;
        background-color: @color-hjernesundtesten4;
        transform: rotate(-90deg);
      }
      i {
        font-size: 50px;
        margin-right: (37/18)*1rem;
        color: @color-icon;
      }
      .improvement-indicator {
        background-color: @color-secondary;
        border: 2px solid @white;
        color: @white;
        font-size: 1rem;
        .fontSemibold;
        height: 25px;
        left: -12px;
        line-height: 1em;
        padding: 2px 0;
        position: absolute;
        top: 55%;
        width: 25px;
        .border-radius(25px);
        .translate(0, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .accordion-toggle.collapsed {
      &::after {
        transform: rotate(90deg);
      }
    }
  }
  .panel-body {
    color: @color-text;
  }
}
