//
// Forms: Conversational UI
// --------------------------------------------------

// Remove unwanted padding.
.cui {
  padding-top: 0;
  padding-bottom: 0;
}

// Set up flexbox for a column, bottom first chat container.
.chat-container {
  .flex-display(flex);
  .flex-direction(column);
  //.flex-justify-content(flex-end);
  padding: 15px 5px;
  width: 100%;
  @media(min-width:@bp-medium){
    min-height: calc(100vh - 60px);
  }
}

// General styling chat messages. Default styling is the bot reply.
.chat-message {
  .flex-align-items(center);
  .flex-display(flex);
  margin-bottom: 0.25rem;
  transition: all .5s ease-in-out;
  width: 100%;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-name: fly-in;
  animation-name: fly-in;
}

.chat-message + .chat-message--input {
  .chat-message__inner {
    &:before{
      display: none;
    }
  }
}

.chat-message + .chat-message--client {
  .chat-message__inner {
    &:before{
      content:"";
      border-style: solid;
      border-width: 5px 0 5px 8px;
      border-color: transparent transparent transparent @color-hjernesundtesten4;
      position: absolute;
      top: 1.6rem;
      left: unset;
      right: -7px;
    }
  }
}

// Add extra margin-top when the next message is from the other conversing party.
.chat-message + .chat-message--client,
.chat-message--client + .chat-message:not(.chat-message--client) {
  margin-top: 1.5rem;
}
.chat-message--client + .chat-message--client {
  margin-top: 0;
}
// Loading message bubble.
.chat-loading {
  height: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
  position: relative;
  text-align: center;
  width: 46px;
  span {
    -webkit-animation: wave 1.3s linear infinite;
    animation: wave 1.3s linear infinite;
    background: #d8d8d8;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 3px;
    width: 10px;
    &:nth-child(2) {
      animation-delay: -1.1s;
    }
    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}
// Client response.
.chat-message--client {
  .flex-direction(row-reverse);
  .chat-message__inner {
    background-color: @color-hjernesundtesten4;
    border-radius: 2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    p {
      color: @white;
    }
  }
  .form-item {
    margin-bottom: 0;
  }
}

// Client input.
.chat-message--input {
  .chat-message__inner {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    text-align: right;
  }
}

// Content of chat message.
.chat-message__inner {
  position: relative;
  background-color: @white;
  border-radius: 1.2rem;
  display: inline-block;
  max-width: ~"calc(100% - 65px)";
  padding: 1rem 1.5rem;
  &:before{
    content:"";
    border-style: solid;
    border-width: 5px 8px 5px 0;
    border-color: transparent #ffffff transparent transparent;
    position: absolute;
    top: 1.6rem;
    left: -7px;
  }
  .chat-message--video & {
    width: ~"calc(100% - 65px)";
  }
  p,
  .message {
    color: @color-text;
    font-weight: 600;
    line-height: 1.666666667em;
    margin-bottom: 0;
    .media--type-image img {
      height: auto;
      max-width: 100%;
      width: auto;
    }
    a:not(.btn) {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    p.description {
      font-style: italic;
    }
  }
  ul {
    margin-block-end: 0;
  }
}

// CHECKBOXES
.cui {
  .form-checkboxes {
    background-color: @color-hjernesundtesten4;
    border-radius: 0.2777777778rem;
    overflow: hidden;
    border-radius: 2rem;
    .form-item {
      margin-bottom: 0;
      &:first-child{
        input[type="checkbox"] {
          + label {
            padding: 1.5rem 1rem 1rem 2.5rem;
            &::after {
              top: 0.5rem;
              height: calc(100% - 0.5rem);
            }
          }
        }
      }
      &:last-child{
        input[type="checkbox"] {
          + label {
            padding: 1rem 1rem 1.5rem 2.5rem;
            &::after {
              height: calc(100% - 0.5rem);
            }
          }
        }
      }
    }
    input[type="checkbox"] {
      + label {
        opacity: 0.6;
        border-bottom: none;
        .fontSemibold;
        padding: 1rem 1rem 1rem 2.5rem;
        min-width: 250px;
        transition: background-color 0.25s ease;
        &::before {
          display: none;
        }
        &::after {
          background-image: url('../images/check-white.svg') !important;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 26px 22px;
          height: 100%;
          left: 0;
          opacity: 0.6;
          top: 0;
          width: 2.5rem;
        }
      }
      &:checked + label {
        opacity: 1;
      }
      &:hover + label {
        background: rgba(0,0,0,0.3);
      }
    }
  }
}

// RADIO BUTTONS
.cui {
  .form-radios {
    &.form-radios-2,
    &.form-radios-multiple {
      .form-item {
        margin-bottom: 0.25rem;
      }
      input[type="radio"] {
        + label {
          background-color: @color-hjernesundtesten4;
          border-color: darken(@color-hjernesundtesten4, 7%);
          border-radius: 2rem;
          color: @white !important;
          font-size: 1rem;
          line-height: 1.2rem;
          .fontSemibold;
          min-width: 6.6666666667rem;
          padding: 1rem;
          transition: background-color .2s ease-in-out, color .2s ease-in-out;
          &:hover {
            background-color: @color-hjernesundtesten4-hover;
            border-color: @color-hjernesundtesten4-hover;
          }
          &::before,
          &::after {
            display: none;
          }
          i {
            display: block;
            font-size: 0.8rem;
            font-family: 'Epilogue', sans-serif;
            font-weight: 400;
            font-style: normal;
            line-height: 1.2rem;
            padding: 0.25rem 0 0 0;
          }
        }
      }
    }
  }
}

// TEXTFIELD AND TEXTAREA
.cui {
  .form-type-textfield {
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="number"],
    input[type="date"] {
      height: 3rem;
    }
    input,
    textarea {
      padding: 1rem;
      border-radius: 2rem;
      background-color: #fff;
      color: @black;
      text-align: center;
      transition: all .2s ease-in-out;
      border: 1px solid #fcebf3;
      &:focus {
        color: @black;
        background-color: rgba(255,255,255, 1);
      }
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: rgba(0,0,0, 0.2);
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: rgba(0,0,0, 0.2);
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: rgba(0,0,0, 0.2);
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: rgba(0,0,0, 0.2);
      }
    }
    input[type="text"],
    input[type="date"] {
      .fontRegular;
      text-align: left;
    }
    textarea {
      .fontRegular;
      text-align: left;
      width: 100%;
    }
  }
  .chat-message--textarea {
    .chat-message__inner {
      width: 100%;
    }
  }
}

// RANGE SLIDER
.cui {
  .chat-message--question-slider {
    .chat-message__inner {
      width: 100%;
    }
  }
  .form-type-range {
    margin: 1rem 0;
    width: 100%;
  }
  .rangeslider {
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: inset 3px 3px 6px 0 rgba(0,0,0,0.50);
    .rangeslider__handle {
      background-color: @white;
    }
  }
}

// CHAT NAVIGATION LINKS
.cui {
  .chat-message__navigation {
    margin-top: 10px;
    text-align: right;
    color: @gray;
    a {
      color: @gray;
    }
  }
}

@media (min-width: @bp-small) {
  .chat-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

// PROGRESS BAR SHORT LIBRA TEST
.question-progress-bar {
  display: flex;
  justify-content: space-evenly;
  gap: 4px;
  width: 100%;
  margin-block-end: 40px;
  @media (min-width: @bp-medium) {
    gap: 10px;
    margin-block-end: 56px;
  }
  .question-progress-done,
  .question-progress-todo {
    background-color: @white;
    border-radius: 4px;
    height: 15px;
    width: 100%;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    @media (min-width: @bp-medium) {
      height: 10px;
    }
  }
  .question-progress-done {
    background-color: @color-hjernesundtesten4;
  }
}
