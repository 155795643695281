//
// Typography
// --------------------------------------------------

/* epilogue-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/epilogue/epilogue-v17-latin-regular.woff2') format('woff2');
}

/* epilogue-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/epilogue/epilogue-v17-latin-500.woff2') format('woff2');
}

/* epilogue-600 - latin */
@font-face {
  font-display: swap;
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/epilogue/epilogue-v17-latin-600.woff2') format('woff2');
}

/* epilogue-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/epilogue/epilogue-v17-latin-700.woff2') format('woff2');
}

/* epilogue-900 - latin */
@font-face {
  font-display: swap;
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/epilogue/epilogue-v17-latin-900.woff2') format('woff2');
}

.fontCursive {
  font-family: 'Epilogue', sans-serif;
  font-weight: normal;
}

.fontLight {
  font-family: 'Epilogue', sans-serif;
  font-weight: 200;
}

.fontRegular {
  font-family: 'Epilogue', sans-serif;
  font-weight: 400;
}

.fontItalic {
  font-family: 'Epilogue', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.fontSemibold {
  font-family: 'Epilogue', sans-serif;
  font-weight: 600;
}

.fontBold {
  font-family: 'Epilogue', sans-serif;
  font-weight: 700;
}

.fontBoldItalic {
  font-family: 'Epilogue', sans-serif;
  font-weight: 700;
  font-style: italic;
}

// HEADINGS
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #222; // @color-primary;
  .fontBold;
  line-height: 1.3;
  margin: 0 0 (@base-line-height / 3);

  small,
  .small {
    font-weight: normal;
    line-height: 1;
  }
}

h1, .h1 {
  font-size: 2.0588235294rem;
}
h2, .h2 {
  font-size: 1.5294117647rem;
}
h3, .h3 {
  font-size: 1.4117647059rem;
}
h4, .h4 {
  font-size: 1.1764705882rem;
}
h5, .h5 {
  font-size: 1rem;
}
h6, .h6 {
  font-size: 1rem;
}

// BODY
p {
  color: @text-color;
  .fontRegular;
  font-size: 1rem;
  margin: 0 0 (@base-line-height);
  &.intro {
    .fontBold;
    em {
      .fontBoldItalic;
    }
  }
  &.intro {
    color: @black;
  }
}

b,
strong {
  .fontBold;
}
em {
  .fontItalic;
}
strong em,
em strong {
  .fontBoldItalic;
}

// LISTS
ul, ol, dl {
  .fontRegular;
  font-size: 1rem;
  margin: 0 0 (@base-line-height);
  ul, ol, dl {
    margin-bottom: 0;
  }
}

// MISC
small,
.small {
  font-size: 80%;
}

mark,
.mark {
  background-color: @color-focus;
  padding: .2em;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
