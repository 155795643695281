//
// Header
// --------------------------------------------------

.region-header {
  margin: 0 auto;
  max-width: 1024px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 50;
}

// .has-absolute-header #header {
//   background: none;
//   position: absolute;
//   width: 100%
// }

#header {
  padding: 15px 0;
  position: relative;
  // background: rgba(0,0,0,0.3);
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: @color-hjernesundtesten1;
    z-index: -2;
  }
  // &:after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: url(../images/background-curves-transparent.png) repeat bottom left;
  //   z-index: -1;
  //   opacity: 0.1;
  // }
  &.no-padding {
    background: rgba(0,0,0,0);
    padding: 0;
  }
  .flexbox & {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
  .col-xs-12 {
    .flex-align-items(center);
    .flex-display(flex);
    .flex-justify-content(space-between);
    .no-flexbox & {
      display: block;
      .actions-wrapper {
        float: left;
        + .actions-wrapper {
          float: right;
        }
      }
    }
    a {
      img {
        max-width: 160px;
        height: auto;
      }
    }
  }
  .actions-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .text {
      color: @white;
      display: none;
      margin-right: 10px;
    }

    .btn {
      .fontSemibold;
    }
  }
}

@media (min-width: @bp-x-small) {
  #header {
    .actions-wrapper {
      .text {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
