//
// Listing
// --------------------------------------------------

.listing {
  list-style: none;
  margin: 0;
  padding: 0;
  a {
    text-decoration: none !important;
  }
  // Reset (un)ordered listing inside a listing
  ul, ol {
    margin-bottom: @base-line-height;
    ul, ol, dl {
      margin-bottom: 0;
      margin-top: 2px;
    }
    ul {
      list-style-type: circle;
    }
  }
  ul {
    list-style-type: disc;
  }
  .image {
    position: relative;
    z-index: 4;
    img {
      display: block;
      height: auto;
      max-width: 100%;
      width: auto;
    }
  }
  .list-item {
    .clearfix;
  }
}

// CHECKS
.listing-checks {
  margin-left: auto;
  margin-right: auto;
  max-width: 350px;
  .list-item {
    .fontRegular;
    line-height: 1.1764705882em;
    padding: 10px 0 10px 40px;
    position: relative;
  }
  .icon-check {
    color: @color-secondary;
    font-size: 1.55rem;
    left: 0;
    position: absolute;
    top: 5px;
  }
  .row-home & {
    max-width: 400px;
    .list-item {
      font-size: 1.1176470588rem;
      padding-top: 11px;
      padding-bottom: 11px;
    }
    .icon-check {
      top: 9px;
    }
  }
}

// REWARDS / BELONINGEN
.listing-rewards {
  .flex-align-items(center);
  .flex-display(flex);
  .flex-wrap(wrap);
  position: relative;
  margin: 2rem auto;
  text-align: center;
  padding-bottom: 2rem;
  &::after {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    height: 3px;
    width: calc(100% - 20px);
    background-image: url(../images/Line.svg);
    background-repeat: repeat-x;
    background-position: calc(100% - 20px) bottom;
  }
  &:last-of-type {
    &::after {
      display: none;
    }
  }
  .list-item {
    padding: 8px 5px;
    width: 20%;
    img {
      height: auto;
      max-width: 100%;
      width: auto;
    }
    &.is-star {
      color: @color-secondary;
      display: inline-block;
      font-size: 1rem;
      .fontBold;
      width: auto;
      img {
        width: 50px;
      }
      .star {
        display: inline-block;
        margin-left: 2px;
        position: relative;
      }
      span {
        color: @white;
        left: 50%;
        line-height: 1em;
        position: absolute;
        top: 50%;
        .translate(-50%, -40%);
      }
    }
  }
  // Full version
  &.listing-rewards-full {
    display: block;
    text-align: left;
    .list-item {
      margin-bottom: 0.5rem;
      padding: 0 0.5rem;
      width: 100%;
      a {
        .flex-align-items(center);
        .flex-display(flex);
        padding-right: 2rem;
        position: relative;
        width: 100%;
      }
      .image {
        .flex(0 1 auto);
        height: 4.1666666667rem;
        width: 4.1666666667rem;
        img {
          display: block;
          height: auto;
          padding: 6% 4%;
          max-width: 90%;
        }
      }
      .info {
        .flex(1 0);
      }
      h4 {
        font-size: 0.9444444444rem;
        margin-bottom: -0.3888888889rem;
      }
      .meta {
        color: rgba(67, 41, 87, 0.4);
        font-size: 0.7777777778rem;
        .fontItalic;
      }
      svg {
        position: absolute;
        right: 0;
        top: 50%;
        .translate(0, -50%);
        fill: @color-secondary;
        color: @color-secondary;
      }

      &:nth-child(1) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
      &:nth-child(2) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
      &:nth-child(3) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
      &:nth-child(4) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
      &:nth-child(5) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
      &:nth-child(6) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
      &:nth-child(7) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
      &:nth-child(8) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
      &:nth-child(9) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
      &:nth-child(10) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
      &:nth-child(11) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
      &:nth-child(12) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
      &:nth-child(13) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
      &:nth-child(14) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
      &:nth-child(15) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
      &:nth-child(16) {
        .image {
          img {
            .makeRandom(0, 360, 1);
            transform: rotate(calc(1deg * @randNum;));
          }
        }
      }
    }
  }
}
.rewards {
  margin-top: 2rem;

  h4 {
    margin-bottom: 0.5rem;
  }
  ul.listing-rewards {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
  &__type {
    display: block;
    font-size: (20/18) * 1rem;
    font-weight: bold;
    color: @color-secondary;
    margin-block-end: 1rem;
  }
}

// RESULTS
.listing-results {
  margin-top: 15px;
  margin-bottom: 15px;
  .list-item {
    line-height: 1.4705882353em;
    margin-bottom: 25px;
    padding-left: 32px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
  }
  em {
    color: #959595;
  }
  .icon {
    background-color: @color-primary;
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 20px;
    .border-radius(20px);
    &.icon-previous {
      background-color: #b7b7b7;
      .opacity(0.5);
    }
  }
}

// SCORES
.listing-scores {
  margin-top: 1.4117647059rem;
  .list-item {
    margin-bottom: 1.5294117647em;
  }
  h4 {
    font-size: 1rem;
    margin-bottom: 0.7647058824em;
  }
  .graph {
    position: relative;
    .graph-line {
      background: rgb(240,58,25);
      background: -moz-linear-gradient(left,  rgba(240,58,25,1) 0%, rgba(247,148,29,1) 35%, rgba(141,198,63,1) 69%, rgba(57,181,74,1) 100%);
      background: -webkit-linear-gradient(left,  rgba(240,58,25,1) 0%,rgba(247,148,29,1) 35%,rgba(141,198,63,1) 69%,rgba(57,181,74,1) 100%);
      background: linear-gradient(to right,  rgba(240,58,25,1) 0%,rgba(247,148,29,1) 35%,rgba(141,198,63,1) 69%,rgba(57,181,74,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f03a19', endColorstr='#39b54a',GradientType=1 );
      height: 9px;
      .border-radius(9px);
    }
    .graph-result {
      background-color: @color-primary;
      display: block;
      height: 20px;
      left: 0;
      position: absolute;
      top: -6px;
      width: 20px;
      z-index: 2;
      .border-radius(20px);
      .translate(-10px, 0);
      &.graph-result-previous {
        background-color: #b7b7b7;
        z-index: 1;
        .opacity(0.5);
      }
    }
  }
}

// THEMA'S
.listing-themas {
  padding: 1rem 0;
  .list-item {
    padding: 0.5rem;
    &.is-active {
      a {
        border: 2px solid #ccc;
        color: rgba(0,0,0,1);
        .icon {
          .improvement-indicator {
            border-color: @white;
          }
        }
        .icon-chevron-right {
          color: #617657;
        }
        h5 {
          .fontBold;
        }
      }
    }
  }
  a {
    .flex-align-items(center);
    .flex-display(flex);
    // border: 2px dashed rgba(0,0,0,0.1);
    padding: 8px 35px 8px 12px;
    position: relative;
    .border-radius(100px);
    -webkit-transition: background-color .15s ease, color .15s ease;
    -o-transition: background-color .15s ease, color .15s ease;
    transition: background-color .15s ease, color .15s ease;
    color: rgba(0,0,0,1);
    &:hover {
      background: rgba(0,0,0,0.05);
      color: rgba(0,0,0,1);
    }
  }
  .icon {
    margin-right: 16px;
    position: relative;
    text-align: center;
    width: 36px;
    i {
      color: @color-secondary;
      font-size: 2rem;
      &.icon-alcohol,
      &.icon-dieet {
        font-size: 1.8rem;
      }
    }
    .improvement-indicator {
      background-color: @color-secondary;
      border: 2px solid @white;
      color: @white;
      font-size: 0.8235294118rem;
      .fontSemibold;
      height: 21px;
      left: -5px;
      line-height: 1em;
      padding: 1px 0;
      position: absolute;
      top: 50%;
      width: 21px;
      .border-radius(21px);
      .transition(border-color .15s ease);
      .translate(0, -50%);
    }
  }
  h5 {
    color: inherit;
    .fontRegular;
    font-size: 1.1rem;
    margin-bottom: 0;
  }
  .icon-chevron-right {
    color: rgba(0,0,0,0.1);
    right: 12px;
    position: absolute;
    top: 50%;
    .transition(opacity .15s ease-in-out);
    .translate(0, -50%);
  }
  .is-monitor & {
    .icon {
      i {
        color: #b7b7b7;
      }
    }
  }
  .is-hold-on & {
    .icon {
      i {
        color: @color-success;
      }
    }
  }
  .no-flexbox & {
    a {
      display: block;
    }
    .icon, h5 {
      .inline-block();
      vertical-align: middle;
    }
  }
}

@media (min-width: 420px) {
  .listing-rewards {
    .list-item {
      width: 16.6666666667%;
    }
  }
}

@media (min-width: 600px) {
  .listing-rewards {
    .list-item {
      width: 14.2857142857%;
      &.is-star {
        img {
          width: 60px;
        }
      }
    }
    &.listing-rewards-full {
      .list-item {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}

@media (min-width: 900px) {
  .listing-rewards {
    .list-item {
      width: 12.5%;
    }
  }
}

@media (min-width: @bp-medium) {
  .listing-themas {
    .flex-display(flex);
    .flex-wrap(wrap);
    .list-item {
      width: 50%;
    }
    .no-flexbox & {
      .spacing();
      .list-item {
        .inline-block();
        vertical-align: top;
        width: ~"calc(50% - 20px)";
        .spacing-normal();
      }
    }
  }
}
