//
// Home
// --------------------------------------------------

.row-home {
  padding: 50px 25px;
  padding-top: 10px;
  h1 {
    .fontBold;
    font-size: 3.3333rem;
    line-height: 1.033333333em;
    margin-bottom: 0.533333333em;
  }
  h3 {
    color: @color-secondary;
  }
  &.row-primary {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: @color-hjernesundtesten1;
      z-index: 1;
    }
    // &:after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: url(../images/background-curves-transparent.png) repeat top left;
    //   z-index: 2;
    //   opacity: 0.1;
    // }
    p {
      color: @black;
    }
  }
  &.row-secondary {
    background-color: @color-secondary;
  }
  &.row-tertiary {
    background-color: @color-tertiary;
  }
  &.row-quaternary {
    background-color: @color-quaternary;
  }
  &.row-quinary {
    background-color: @color-quinary;
  }
  &.row-white {
    background-color: @white;
  }
  .row-inner {
    position: relative;
    z-index: 3;
    margin-left: auto;
    margin-right: auto;
    max-width: 620px;
  }
  .logo {
    margin-bottom: 65px;
    text-align: center;
  }
  .fast-button {
    .flex-align-items(center);
    justify-content: center;
    color: @white;
    .flex-display(flex);
    .fontBold;
    font-size: 1.111111111rem;
    line-height: 1.2em;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    width: fit-content;
    margin-inline: auto;
    margin-bottom: 30px;
    .transition(all .25s ease);
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      background-color: @color-hjernesundtesten2;
      border-radius: 4px 0 0 4px;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 13px;
      padding-bottom: 13px;
      b {
        font-size: 12px;
      }
    }
    span:last-child {
      // color: #000;
      // background-color: #ffea64;
      border-radius: 0 4px 4px 0;
      .flex(0 1 auto);
      padding-left: 1rem;
      padding-right: 1rem;
    }
    &:hover {
      span {
        background-color: darken(@color-hjernesundtesten2, 15%);
      }
      // span:last-child {
      //   color: #fff;
      // }
    }
    i {
      display: inline-block;
      font-size: 2rem;
      margin-right: 7px;
      vertical-align: middle;
    }
    b {
      // display: inline-block;
      // font-size: 0.8235294118rem;
      font-weight: 600;
      // vertical-align: middle;
      // .translate(0, 3px);
    }
  }
  // INTRO
  &#row-intro {
    text-align: center;
    img {
      max-width: 100%;
      height: auto;
    }
    .row-inner:first-child{
      p {
        font-size: 22px;
        font-weight: 500;
        line-height: 35px;
        margin-block-end: 33px;
      }
      ul {
        width: fit-content;
        margin-inline: auto !important;
        li {
          font-size: 22px;
        }
      }
    }
    .row-inner:last-child{
      .listing-checks {
        max-width: 400px;
        text-align: left;
      }

      .home-image {
        max-width: 400px;
        margin: 30px auto;
        img {
          display: block;
          height: auto;
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
  // USP & DEVICES
  &#row-usp {
    padding-bottom: 0;
    .row-inner {
      max-width: none;
    }
    .home-image {
      max-width: 400px;
      margin: 30px auto;
      img {
        display: block;
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }
    .devices {
      margin: 25px -25px 0;
      width: ~"calc(100% + 50px)";
      img {
        display: block;
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }
  }
  // WORKING
  &#row-working {
    p {
      line-height: 1.4705882353em;
      &:last-of-type {
        margin-bottom: 1rem;
      }
      a {
        color: @white;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .btn-transparent {
      color: @white;
      padding-left: 0;
      padding-right: 0;
      &:hover {
        color: @color-secondary;
      }
    }
  }
  // PARTICIPATE
  &#row-participate {
    p {
      line-height: 1.4705882353em;
    }
  }
  // INFO
  &#row-info {
    .app-stores {
      margin-bottom: 30px;
      text-align: center;
      a {
        display: inline-block;
        padding: 3px 8px;
        vertical-align: top;
      }
    }
    p {
      font-size: 0.8823529412rem;
      line-height: 1.6666666667em;
      a {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .logos {
      .flex-align-items(center);
      .flex-display(flex);
      .flex-wrap(wrap);
      margin: 0 -25px;
      a {
        display: block;
        padding: 25px;
        text-align: center;
        width: 100%;
        .opacity(0.3);
        .no-touchevents & {
          filter: grayscale(100%);
        }
        &:hover {
          .opacity(1);
          .no-touchevents & {
            filter: grayscale(0%);
          }
        }
      }
      img {
        display: block;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
      }
    }
  }
  // SHARE
  &#row-share {
    padding: 35px 25px;
    text-align: center;
    span,
    .social-links {
      display: inline-block;
      vertical-align: middle;
    }
    span {
      color: inherit;
      font-size: 15px;
      margin-right: 10px;
    }
    .social-links {
      .flex-align-items(center);
      display: inline-flex;
      white-space: nowrap;
    }
    a {
      color: @white;
      margin: 0 1px;
      padding: 5px;
      text-decoration: none;
    }
    .icon {
      .flex-align-items(center);
      .flex-display(flex);
      .flex-justify-content(center);
      height: 38px;
      width: 38px;
      .border-radius(38px);
    }
    .facebook .icon { background-color: @color-facebook; }
    .twitter .icon { background-color: @color-twitter; }
    .linkedin .icon { background-color: @color-linkedin; }
    .mail .icon { background-color: @color-secondary }
  }
  // LOGO
  &#row-logo {
    text-align: center;
    .row-inner {
      margin-top: 1rem;
    }
    img {
      max-width: 200px;
      height: auto;
    }
  }
}

@media (min-width: 600px) {
  .row-home {
    // INFO
    &#row-info {
      .logos {
        //.flex-wrap(nowrap);
        margin: 0;
        padding: 0 25px;
        a {
          padding: 20px 25px;
          width: 50%;
        }
      }
    }
  }
}

@media (min-width: @bp-small) {
  .row-home {
    padding: 45px 30px;
    // INTRO
    &#row-intro {
      padding-top: 20px;
      padding-bottom: 60px;
    }
    // USP & DEVICES
    &#row-usp {
      padding-top: 60px;
      .devices {
        margin-left: -30px;
        margin-right: -30px;
        width: ~"calc(100% + 60px)";
      }
    }
    // SHARE
    &#row-share {
      padding: 55px 30px;
    }
  }
}
// START
.row-start {
  .subscribe {
    position: relative;
    background-color: white;
    border-radius: (15/18) * 1rem;
    max-width: 630px;
    padding: 30px 15px;
    margin-bottom: 6rem;
    &::after {
      content: "";
      position: absolute;
      height: 33px;
      width: 129px;
      background-image: url(../images/box-arrow.svg);
      background-repeat: no-repeat;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      bottom: -33px;
    }
    p {
      text-align: left;
    }
    a {
      text-decoration: underline;
    }
    h3 {
      font-size: (24/18) * 1rem;
      text-align: left;
      color: @black;
      // margin-left: (15/18) * 1rem;
      margin-bottom: 1rem;
    }
    .fast-button {
      padding-top: 1.5rem;
      span:first-child {
        border-radius: 34px;
        justify-content: center;
      }
    }
  }
}
